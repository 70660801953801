import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p  className="App-contain" >
        Let's do some coolify stuff
        </p>
        <a
          className="App-link"
          href="https://playground.test-deg.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go to coolify
        </a>
      </header>
    </div>
  );
}

export default App;
